.img-card {
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 200px;
}

.img-card > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(2, 101, 182, 0.364);
  border-radius: 0.5rem;
}

@media (min-width: 600px) {
  .img-card {
    padding: 0 0.2rem;
    height: 230px;
  }
}
