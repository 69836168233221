.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  min-width: 80%;
  padding: 1rem;
  border-radius: 1rem;
}

@media (min-width: 500px) {
  .modal-container {
    max-width: 80%;
    min-width: 70%;
  }
}

@media (min-width: 575px) {
  .modal-container {
    max-width: 70%;
    min-width: 0;
  }
}

@media (min-width: 800px) {
  .modal-container {
    padding: 1rem 2rem;
  }
}
