.masonry-container {
  overflow-y: inherit;
  min-height: 20rem;
  padding: 3rem 0;
  width: 100%;
  background-color: rgba(7, 148, 148, 0.45);
  box-shadow: inset 0px 21px 14px 0px #fff;
}

.grid-masonry-list {
  max-height: 100% !important;
}

.chips-inside-photos {
  position: absolute;
  top: 10px;
  left: 5px;
}

@media (min-width: 750px) {
  .masonry-container ul {
    column-count: 3 !important;
  }
}

@media (min-width: 1160px) {
  .masonry-container ul {
    column-count: 4 !important;
  }
}
