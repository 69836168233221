.img-container {
  position: relative;
  height: 23.5rem;
}

.img-container > img {
  z-index: -1;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 870px;
  bottom: 0;
}

.div-shadow {
  box-shadow: 0px 3px 12px 18px #ffffff;
}

.grid-container-title {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  color: rgba(2, 101, 182, 0.918);
  font-size: 0.7rem;
}

@media (min-width: 600px) {
  .img-container {
    height: 25.5rem;
  }
  .grid-container-title {
    font-size: 1rem;
  }
}

@media (min-width: 900px) {
  .grid-container-title {
    font-size: 1.3rem;
  }
}
