.faved-title {
  font-size: 1.6rem;
  font-weight: 400;
  color: rgb(224, 223, 223);
  text-shadow: 1px 1px rgb(0, 0, 0);
}

.no-faved-imgs {
  color: #fff;
  margin: 2rem;
}

.no-faved-imgs p {
  margin-top: 0.5rem;
}

.no-faved-imgs-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

@media (min-width: 600px) {
  .no-faved-imgs {
    margin: 4rem;
  }
  .no-faved-imgs p {
    margin-top: 1rem;
  }
}
