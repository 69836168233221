@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
  list-style: none;
}

.slick-list {
  margin: auto 10px;
}

.css-1kcnzpi-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 90, 112, 0.185),
    transparent
  ) !important;
}

.MuiImageList-root {
  margin: auto;
  width: 94%;
  max-width: 90rem;
}

.MuiImageListItem-root {
  max-height: 340px;
}

.MuiImageListItem-img {
  cursor: pointer;
  border-radius: 0.8rem;
  height: 100% !important;
}

.MuiImageListItemBar-root {
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.MuiImageListItem-root .MuiImageListItemBar-root {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.MuiImageListItem-root:hover .MuiImageListItemBar-root {
  opacity: 1;
  transition: all 0.3s ease-out;
}

.MuiImageListItemBar-actionIcon {
  display: flex;
  justify-content: end;
  margin-right: 4px;
  height: 60px;
  padding: 7px 0;
  align-items: start;
  min-width: 64px;
}

a.active {
  padding-bottom: 0.25rem;
  border-bottom: 4px solid rgba(59, 167, 255, 0.864);
}

a.active h3 {
  color: rgb(59, 167, 255);
}

a#author-head-title {
  text-decoration: none;
  color: inherit;
}

.override-opacity {
  opacity: 1 !important;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 16rem;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 8px 1px;
  border-radius: 6px;
  word-wrap: break-word;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -8rem;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.4s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 600px) {
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
    line-height: 30px !important;
  }
  .slick-next {
    width: auto;
  }
  .slick-dots li button::before {
    font-size: 12px !important;
  }
}

@media (min-width: 767px) {
  .MuiImageList-root {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (min-width: 992px) {
  .MuiImageList-root {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .tooltip .tooltiptext {
    width: 20rem;
    margin-left: -10rem;
  }
}

@media (min-width: 1370px) {
  .MuiImageList-root {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
