.card-container {
  position: inherit;
  margin: auto;
  margin-top: 3rem;
  padding: 1rem 0.3rem;
  height: 80%;
  width: 90%;
  max-width: 80rem;
  background-color: rgba(0, 0, 0, 0.35);
  text-align: center;
  z-index: 1;
  border-radius: 0.8rem;
}

@media (min-width: 600px) {
  .card-container {
    padding: 1rem;
  }
}
