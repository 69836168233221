.grid-container {
  padding: 3rem 0;
  width: 100%;
  box-shadow: inset 0px 21px 14px 0px #fff;
}

.placeholder-card-grid {
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.31) !important;
}
